.page-wrapper {
  min-height: 215px;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 0.25rem 0.4rem !important;
  font-size: 0.875rem !important;
  line-height: 0.5 !important;
  border-radius: 0.2rem !important;
}

.card-no-border .sidebar-nav > ul > li > a.active {
  background: #1e88e5 !important;
  color: #fff;
}

.card-no-border .sidebar-nav > ul > li > a:hover {
  background: #1e88e5 !important;
  color: #fff;
}

.titlepage h4 {
  padding-top: 2em;
  padding-bottom: 1.5em;
}

.container-home {
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-gap: 10px;
  justify-content: center;
}

.align-self-center > h3 {
  font-size: 20px !important;
}

.active > a {
  color: #ffffff !important;
}

.circulo {
  position: absolute;
  display: flex;
  justify-items: center;
  justify-content: center;
  height: 8px;
  width: 8px;
  background: #f37373;
  top: 10px;
  border-radius: 50%;
  animation-name: desbanecer;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  left: 2px;
  animation-delay: 0.2s;
}

.circulo1 {
  position: absolute;
  height: 8px;
  width: 8px;
  background: #2dcc75;
  top: 10px;
  border-radius: 50%;
  animation-name: desbanecer;
  animation-duration: 1s;
  animation-delay: 0.15s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  left: 12px;
}

.circulo2 {
  position: absolute;
  height: 8px;
  width: 8px;
  background: #f3d173;
  top: 10px;
  border-radius: 50%;
  animation-name: desbanecer;
  animation-delay: 0.1s;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  animation-iteration-count: 1;
  left: 22px;
}

.circulo_loading {
  justify-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;
  background: #f37373;
  top: 25px;
  border-radius: 50%;
  animation-name: desbanecer;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  left: 2px;
  animation-delay: 0.2s;
}

.circulo1_loading {
  height: 10px;
  width: 10px;
  background: #2dcc75;
  top: 25px;
  border-radius: 50%;
  animation-name: desbanecer;
  animation-duration: 1s;
  animation-delay: 0.15s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  left: 16px;
}

.circulo2_loading {
  height: 10px;
  width: 10px;
  background: #f3d173;
  top: 25px;
  border-radius: 50%;
  animation-name: desbanecer;
  animation-delay: 0.1s;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  animation-iteration-count: 1;
  left: 31px;
}
.active {
  background: #6583a5;
  color: #fff !important;
}
header.topbar {
  width: 100% !important;
}
.rdt_Table {
  height: auto !important;
}
@keyframes desbanecer {
  0% {
    transform: scale(1) translateY(0);
  }

  75% {
    transform: scale(1) translateY(0);
  }
  80% {
    transform: scale(1) translateY(10px);
  }
}

@keyframes rectangulo {
  100% {
    opacity: 0;
  }
}
